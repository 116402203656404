@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  font-family: "Mulish", sans-serif !important;
}

.App {
  text-align: center;
}

.navbar-brand:focus-visible {
  outline: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Error_page~.vendor_offer{
  display: none;
}

.Error_page{
  background: #ebebeb;
}

/*------------------Newsletter CSS Start--------------------*/

.news_letter_row{
  padding: 25px 0px 15px 0px;
}

.news_title{
  margin-bottom: 30px;
}

.news_title h4{
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 400;
}

.news_title p{
  font-weight: 100;
  font-size: 18px;
  opacity: 0.7;
}

.news_btn{
  background: #ffa22b !important;
  width: 100%;
  color: #111 !important;
  border-radius: 0px 3px 3px 0px !important;
  border-color: #ffa22b !important;
  font-size: 18px !important;
  height: 45px;
  font-weight: 600 !important;
}

.news_btn:hover, .news_btn:focus, .news_btn:visited{
  background-color: #ff9000 !important;
  border-color: #ff9000 !important;
  color: #111 !important;
}

.news_input .form-control{
  border-radius: 3px 0px 0px 3px;
  height: 45px;
  padding: 5px 20px;
  font-size: 17px;
  border: none;
}


/*------------------Newsletter CSS End--------------------*/



/*------------------Scroll To Top Start--------------------*/

.scrolltop {
  background: #ffa22b;
  color: #fff;
  width: 45px;
  height: 45px;
  line-height: 32px;
  padding: 5px;
  position: fixed;
  right: 15px;
  bottom: 35px;
  font-size: 18px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  z-index: 1050;
  text-align: center;
  display: block;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 25%);
}

.float_call{
  background: #ffa22b;
  color: #fff;
  width: 45px;
  height: 45px;
  line-height: 35px;
  padding: 5px;
  position: fixed;
  right: 15px;
  bottom: 100px;
  font-size: 24px;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  z-index: 1050;
  text-align: center;
  display: block;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 25%);
}

.float_call:hover, .float_call:focus, .float_call:focus-visible{
  color: #fff;
  text-decoration: none;
}

.sticky-top {
  box-shadow: 0 2px 20px rgb(2 8 8 / 12%);
}

.navbar.sticky-top{
  padding: 8px 0px;
}

/*------------------Scroll To Top End--------------------*/

/*------------------Header CSS Start--------------------*/

.navbar-light .navbar-brand {
  padding: 0px;
}

.logo {
  max-width: 170px;
}

.cust-nav a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0px 5px;
  color: #515151 !important;
  padding: 0.4rem 0.5rem;
}

.cust-nav a.active{
  color: #202020 !important;
  font-weight: 700;
}

.cust-nav .app_btn {
  text-transform: capitalize;
  background: rgb(0,0,0);
  background: linear-gradient(45deg, rgb(0 0 0) 50%, rgb(63 63 63) 50%, rgb(0 0 0) 100%);
  color: #fff !important;
  border-radius: 3px;
  padding: 7px 20px !important;
  margin-left: 15px;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.cust-nav .app_btn:hover,
.cust-nav .app_btn:focus,
.cust-nav .app_btn:focus-visible,
.cust-nav .app_btn:visited,
.cust-nav .app_btn:active{
  color: #fff !important;
}

.cust-nav .highlight_call span{
  display: block;
  color: #111;
  text-transform: capitalize;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 1px;
}

.cust-nav .highlight_call {
  font-size: 24px;
  color: #111 !important;
  text-align: left;
  font-weight: 800;
  line-height: 23px;
  padding: 0px;
  letter-spacing: -1px;
  margin-left: 15px;
  padding-left: 15px !important;
  border-left: 1px solid #d9d9d9;
}

.navbar .container{
  max-width: 95%;
}


/*------------------Header CSS End--------------------*/

/*------------------Footer CSS Start--------------------*/

.about_section-2~.vendor_offer{
  display: none;
}

.contactPage_section~.vendor_offer{
  display: none;
}

.content_page~.vendor_offer{
  display: none;
}

.d_app_section~.vendor_offer{
  display: none;
}

.vendor_offer{
  background: #e2fff8;
  position: relative;
  padding: 0px 0px;
  margin-top: 150px;
}

.ven_ofer_content h4{
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 5px;
  letter-spacing: -0.2px;
  padding: 0px 0px 0px 15px;
  text-transform: capitalize;
}

.ven_ofer_content p{
  font-size: 17px;
  font-weight: 600;
  opacity: 0.8;
  margin-bottom: 0px;
  padding: 0px 0px 0px 15px;
}

.vendor_register_btn .btn{
  background-color:#ffa22b;
  border-color: #ffa22b;
  border-radius: 3px;
  padding: 5px 50px;
  font-weight: 700;
  color: #111;
  font-size: 20px;
}

.vendor_register_btn .btn:hover,
.vendor_register_btn .btn:active,
.vendor_register_btn .btn:focus,
.vendor_register_btn .btn:focus-visible{
  background-color:#eb9526 !important;
  border-color: #eb9526 !important;
}

.vendor_offer .shape-img2{
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  filter: brightness(110%) contrast(58%) saturate(68%) blur(0px) hue-rotate(336deg);
}

.vendor_offer .shape-img1{
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  filter: brightness(110%) contrast(58%) saturate(68%) blur(0px) hue-rotate(336deg);
}

.box_Img img{
  margin-top: -80px;
  margin-bottom: 0px;
  padding: 0px 0px 15px 0px;
}

footer{
  background: #0e2e50;
}

footer ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.foot_text ul li {
  border-right: 1px solid #fff;
  line-height: 15px;
  display: inline-block;
  margin: 10px 0px;
}

.foot_text a {
  color: #fff;
  text-decoration: none;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 300;
}

.foot_text ul li:last-child {
  border-right: none;
}

.contact-list li {
  border-right: 1px solid #fff;
  line-height: 15px;
  display: inline-block;
}

.contact-list li:last-child {
  border-right: none;
}

.contact-list li a i{
  font-size: 13px;
}

.contact-list li a {
  padding: 5px 22px;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
}

footer .social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

footer .social-icon ul li {
  display: inline-block;
  padding: 5px 6px;
}

footer .social-icon ul li a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

footer .social-icon ul li h6{
  font-size: 16px;
  font-weight: 300;
}

.copy-right p {
  font-weight: 300;
  letter-spacing: 0.5px;
}

/*------------------Footer CSS End--------------------*/

/*------------------Home Search CSS Start--------------------*/
.home_search {
  padding: 120px 0px 150px 0px;
  background-image: url(assets/search-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #086c6c;
}

.home_search h1 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 7px;
}

.home_search h5 {
  color: #fff;
  font-size: 34px;
  font-weight: 100;
}

.search_input .category_search,
.search_input .location_search,
.search_input .search_btn {
  padding: 0px;
}

.search_btn button {
  border-radius: 0px;
  background-color: #ffa22b;
  border-color: #ffa22b;
  padding: 14px 0px;
  font-size: 18px;
}

.search_btn button:hover,
.search_btn button:focus,
.search_btn button:active,
.search_btn button:focus-visible {
  border-radius: 0px;
  background-color: #ff9000 !important;
  border-color: #ff9000 !important;
  box-shadow: none !important;
}

.search_input .category_search input {
  border-radius: 0px;
  height: 57px;
  padding: .375rem 1.5rem;
  font-size: 18px;
  background: #fff;
  font-weight: 500;
}

.search_input .location_search input {
  border-radius: 0px;
  height: 57px;
  padding: .375rem 1.5rem;
  font-size: 18px;
  font-weight: 500;
}

.category_search .dropdown-menu .dropdown-item:hover,
.category_search .dropdown-menu .dropdown-item:focus,
.category_search .dropdown-menu .dropdown-item:active,
.category_search .dropdown-menu .dropdown-item:focus-visible {
  background-color: #e7e7e7;
  outline: none;
  color: #000;
}

.category_search .dropdown-menu .dropdown-item {
  padding: 4px 15px;
  font-size: 18px;
  font-weight: 300;
  color: #000;
}

.category_search .dropdown-menu {
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: auto;
}

.location_search .dropdown-menu .dropdown-item:hover,
.location_search .dropdown-menu .dropdown-item:focus,
.location_search .dropdown-menu .dropdown-item:active,
.location_search .dropdown-menu .dropdown-item:focus-visible {
  background-color: #e7e7e7;
  outline: none;
  color: #000;
}

.location_search .dropdown-menu .dropdown-item {
  padding: 4px 15px;
  font-size: 18px;
  font-weight: 300;
  color: #000;
}

.location_search .dropdown-menu {
  max-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: auto;
}

.search_input .location_search .input-group button {
  border-radius: 0px;
  background: #fff;
  color: #9b9b9b;
  border: 1px solid #dee2e6;
  border-left: 0px;
  font-size: 18px;
}

.search_cont {
  font-size: 21px;
  margin-top: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 30px;
}

.searchIcon_section{
  border-bottom: 1px solid #c7c7c7;
}

.search_icon ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
}

.search_icon ul li {
  list-style: none;
  margin: 0px;
  border-right: 1px solid #c7c7c7;
  width: 15%;
}

.search_icon ul li:last-child {
  border-right: none;
}

.search_icon ul li a {
  text-decoration: none;
  color: #fff;
  height: 100%;
  display: block;
  text-align: center;
  padding: 20px 5px 30px 5px;
  cursor: pointer;
}

.search_icon ul li a img {
  width: 55px;
}

.search_icon ul li a span {
  display: block;
  font-size: 17px;
  margin-top: 10px;
  color: #000;
  font-weight: 700;
}

.category_search select option {
  font-size: 16px;
  font-weight: 600;
}


/*------------------Home Search CSS End--------------------*/

/*------------------Mobile App CSS Start--------------------*/

.d_app_section {
  padding: 80px 0px 100px 0px;
}

.app_btn_img a img {
  width: 180px;
  margin-right: 15px;
}

.app_content h3 {
  font-weight: 800;
  font-size: 27px;
}

.app_content p{
  font-size: 17px;
}

.app_content h5 {
  font-weight: 800;
  margin-bottom: 20px;
}

/*------------------Mobile App CSS End--------------------*/

/*------------------About Section CSS Start--------------------*/

.about_section-1 {
  padding: 80px 0px 80px 0px;
  background: #e2fff8;
}

.section-title p {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffa22b;
  margin-bottom: 5px;
}

.section-title h2 {
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
}

.about_service {
  padding: 25px;
  text-align: left;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 9%);
  border-radius: 0px;
}

.about_service h2 {
  font-size: 22px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.about_service p {
  font-size: 17px;
  margin-bottom: 0px;
}

.about_section-2 {
  padding: 80px 0px;
  /* background-image: url(assets/about-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center; */
}

.about-col-text {
  background-color: #fff;
  border-radius: 0px;
}

.about-col-text h6 {
  font-size: 24px;
  font-weight: 700;
  color: #ffa22b;
  text-transform: capitalize;
}

.about-col-text h3 {
  font-size: 30px;
  font-weight: 800;
}

.about-col-text h4 {
  font-weight: 700;
  color: #818181;
  margin-top: 10px;
}

.about-col-text p {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 25px;
}

.ico-high {
  background: #ffa22b;
  color: #fff;
  padding: 10px 10px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 24px;
  border-radius: 3px;
  width: 55px;
  text-align: center;
  height: 55px;
}

.searchIcon_section~.breadcrumb_section {
  display: none;
}

.searchIcon_section~.breadcrumb_section {
  display: none;
}

/*------------------About Section CSS End--------------------*/

/*------------------Privact Page CSS Start--------------------*/
.content_page {
  padding: 50px 0px 70px 0px;
}

.content_page_style h2 {
  font-weight: 800;
  font-size: 24px;
}

.content_page_style h3,
.content_page_style h4,
.content_page_style h5,
.content_page_style h6 {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 10px;
}

.content_page_style p,
.content_page_style ul {
  font-size: 17px;
}

.content_page_style p a,
.content_page_style ul li a {
  color: #0da1a1;
}

.content_page_style ul li {
  line-height: 28px;
}

/*------------------Privact Page CSS End--------------------*/

/*------------------Breadcrumb CSS Start--------------------*/
.breadcrumb_section {
  background: #0da1a1;
  padding: 10px 0px;
}

.breadcrumb_section .breadcrumb {
  margin-bottom: 0px;
  padding: 0px 5px;
  line-height: 24px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.breadcrumb-item.active {
  color: #fff !important;
  font-weight: 400;
  font-size: 16px;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #fff !important;
  content: "\f105" !important;
  font-family: "Font Awesome 6 Free" !important;
  font-weight: 900;
  font-size: 13px;
}


/*------------------Breadcrumb CSS End--------------------*/

/*------------------Contact Page CSS Start--------------------*/
.contactPage_section {
  padding: 60px 0px 80px 0px;
}

.contactIcon_outer {
  margin-top: 80px !important;
}

.contact_title h1 {
  font-weight: 800;
  font-size: 32px;
}

.contact_title h4 {
  font-weight: 600;
  font-size: 20px;
}

.form_btn .btn {
  background: #ffa22b;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 100px;
}

.form_btn .btn:hover,
.form_btn .btn:focus,
.form_btn .btn:active,
.form_btn .btn:focus-visible {
  background: #ff9000;
  color: #fff;
}

.contactIcon {
  background: #e2fff8;
  border: 1px solid #bbebeb;
}

.contactInfo .detail_Info h3 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 5px;
}

.contactInfo .detail_Info h4 a {
  text-decoration: none;
  color: #212529;
  font-size: 20px;
  font-weight: 800;
}

.contactInfo .detail_Info h4 {
  margin-bottom: 30px;
}

.contactInfo .detail_Info .social-Info ul {
  margin: 0px;
  padding: 0px;
}

.contactInfo .detail_Info .social-Info ul li {
  display: inline-block;
  margin: 5px 5px;
}

.contactInfo .detail_Info .social-Info ul li a {
  color: #212529;
  font-size: 21px;
}

.contactInfo .icon_Info {
  background: #0da1a1;
  color: #fff;
  width: 85px;
  margin: 0 auto;
  padding: 15px;
  margin-top: -25px;
  border-radius: 100px;
}

.contactInfo .icon_Info .fa {
  font-size: 22px;
}

.cont_form .form-control {
  border-radius: 0px;
  height: 45px;
  border-color: #c3c3c3;
}

/*------------------Contact Page CSS End--------------------*/

/*------------------Plan Section CSS Start--------------------*/

.plan-section {
  padding: 50px 0px;
  margin-bottom: 50px;
}

.plan-section .plan_title h2 {
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 15px;
}

.plan-section .plan_title p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 24px;
}

.plan_tabs .nav-tabs li .nav-link {
  text-decoration: none;
  background: #0da1a1;
  border-radius: 100px;
  padding: 5px 25px;
  color: #fff;
  width: 100%;
  font-weight: 700;
  font-size: 17px;
  display: inline-block;
  border: none;
}

.plan_tabs .nav-tabs li {
  margin: 0px 5px;
}

.plan_tabs .nav-tabs li .nav-link.active {
  background: #ffa22b !important;
  border: none;
}

.plan_tabs .nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: center;
}

.planPrice-style {
  background: #e2fff8;
  height: 100%;
  padding: 10px 10px;
  border-radius: 0 0.375rem 0.375rem 0;
}

.planPrice-style h3 {
  font-weight: 800;
  font-size: 32px;
  color: #000;
  margin: 0px 0px 0px 0px;
  line-height: 24px;
}

.planPrice-style h5 {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 7px;
  color: #000;
  line-height: 14px;
}

.planPrice-style h3 span {
  font-weight: 600;
  font-size: 12px;
  display: block;
  text-transform: uppercase;
  margin-top: 0px;
}

.planPrice-style .planBtn {
  border-radius: 100px;
  padding: 2px 5px;
  background: #ffa22b;
  background: linear-gradient(360deg, rgb(255 162 43) 0%, rgb(255 198 124) 50%, rgb(255 162 43) 100%);
  border: none;
  width: 100%;
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

.planPrice-style .planBtn:hover,
.planPrice-style .planBtn:focus,
.planPrice-style .planBtn:active,
.planPrice-style .planBtn:focus-visible {
  background: #ef9523 !important;
  box-shadow: none !important;
  color: #000 !important;
}

.shine::before {
  content: "";
  position: absolute;
  background: linear-gradient(50deg, rgb(255 255 255 / 0%) 30%, rgb(255 255 255 / 75%) 50%, rgb(255 255 255 / 0%) 70%);
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100px;
  animation: shine 1s infinite linear;
  overflow: hidden;
}

@keyframes shine {
  0% {
    left: -50px;
    opacity: 1;
    transform: translateX(0px) skewX(-15deg);
  }

  100% {
    left: 100px;
    opacity: 0.5;
    transform: translateX(5px) skewX(-15deg);
  }
}

.ad_banner img {
  border-radius: 0.375rem 0 0 0.375rem;
  height: 100%;
}

.planCard-style .card-title {
  font-weight: 800;
  margin-bottom: 2px;
  margin-top: 0px;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.planPrice-style .showNumber {
  border-radius: 100px;
  width: 100%;
  margin-bottom: 7px;
  padding: 1px 0px;
  color: #ffffff;
  font-size: 16px;
  background: #000;
  background: linear-gradient(360deg, rgb(0 0 0) 0%, rgb(56 56 56) 50%, rgb(0 0 0) 100%);
  font-weight: 700;
}

.planPrice-style .showNumber:hover,
.planPrice-style .showNumber:focus,
.planPrice-style .showNumber:active,
.planPrice-style .showNumber:focus-visible {
  background-color: #000 !important;
  color: #fff !important;
  box-shadow: none !important;
}

.planPrice-style .showNumber .fa {
  font-size: 14px;
}

.cardText .ad_miles {
  display: block;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 0px;
  color: #1c8585;
}

.cardText p {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardText .fa,
.cardText .fas {
  color: #a3a3a3;
  font-size: 13px;
  width: 15px;
  text-align: center;
}

.ad_website a {
  color: #0da1a1;
  text-decoration: none;
}

.ad_services .ad_serviceList {
  margin: 0px;
  padding: 0px;
}

.ad_services .ad_serviceList li {
  list-style: square;
  margin-left: 20px;
  line-height: 18px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
}

.ad_worktype {
  border: 1px solid #b7b7b7;
  text-align: center;
  padding: 4px 15px;
  border-radius: 100px;
  width: fit-content;
  font-size: 15px;
  margin-bottom: 5px;
}

.ad_description p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 22px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
}

.ad_banner {
  background: #d0d0d0;
}

.quote_form .cont_form .form-control {
  border-radius: 5px;
  height: 40px;
  border-color: #c3c3c3;
}

.quote_form .cont_form {
  margin-bottom: 15px;
}

.quote_modal .modal-body {
  padding: 15px 20px 25px 20px !important;
}

.quote_modal .modal-header .h4 {
  font-weight: 700;
  font-size: 20px;
}

.quote_modal .modal-header {
  padding: 7px 20px;
  background: #0da1a1;
  color: #fff;
  font-size: 14px;
}

.quote_modal .modal-header .btn-close {
  color: #fff;
}

.quote_modal .modal-dialog {
  margin: 5% auto;
}

.about_section-2~.breadcrumb_section {
  display: none;
}

.about_section-2~.breadcrumb_section {
  display: none;
}

.special_slim_ad{
  margin-top: 50px;
}

.special_slim_ad img{
  border-radius: 3px;
}

#plan-tabs-tabpane-4 .special_slim_ad,
#plan-tabs-tabpane-1 .special_slim_ad,
#plan-tabs-tabpane-5 .special_slim_ad{
 display: none;
}

#plan-tabs-tabpane-4 .plan_ribbon,
#plan-tabs-tabpane-1 .plan_ribbon,
#plan-tabs-tabpane-5 .plan_ribbon{
 display: none;
}


.special_ad_shimmer{
  background: #e7e7e7;
  padding: 10px 10px;
  text-align: left;
  margin-top: 50px !important;
  border-radius: 5px;
}

.special_ad_shimmer h6{
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 0px;
  text-transform: capitalize;
  letter-spacing: -0.5px;
}

.sale_animation{
  animation-name:salePulse;
  -webkit-animation-name:salePulse;
  animation-duration:1s;
  -webkit-animation-duration:1s;
  animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  display: inline-block;
  color: #fff;
  padding: 2px 10px;
  border-radius: 3px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1px;
  vertical-align: bottom;
}

@keyframes salePulse {
  from { background-color: #db004d; }
  50% { background-color: #e90354; -webkit-transform:scale(1.03); }
  to { background-color: #db004d;}
}

.plan_ribbon{
  position:absolute;
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: 25px; /* the top offset */
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 4px 25px 14px calc(20px + var(--r));
  clip-path:
    polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #db004d;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

#plan-tabs-tabpane-3 .planPrice-style h5{
  margin-bottom: 15px;
  font-size: 18px;
}

#plan-tabs-tabpane-3 .planPrice-style h3{
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 30px;
}

#plan-tabs-tabpane-3 .planPrice-style h3 span{
  font-size: 14px;
}

#plan-tabs-tabpane-3 .planPrice-style .showNumber{
  margin-bottom: 10px;
  padding: 4px 0px;
  font-size: 17px;
}

#plan-tabs-tabpane-3 .planPrice-style .planBtn{
  padding: 4px 0px;
  font-size: 17px;
}

#plan-tabs-tabpane-4 .planPrice-style h5{
  margin-bottom: 15px;
  font-size: 18px;
}

#plan-tabs-tabpane-4 .planPrice-style h3{
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 30px;
}

#plan-tabs-tabpane-4 .planPrice-style .showNumber{
  margin-bottom: 10px;
  padding: 2px 0px;
  font-size: 17px;
}

#plan-tabs-tabpane-4 .planPrice-style .planBtn{
  padding: 2px 0px;
  font-size: 17px;
}

#plan-tabs-tabpane-4 .planPrice-style h3 span{
  font-size: 14px;
}

/*------------------Plan Section CSS End--------------------*/

/*------------------Plan Section New CSS Start--------------------*/
.new_plan-section{
  padding: 100px 0px 120px 0px;
  background: #000;
  background-image: url(assets/plan_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.plan_new_content h1{
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 20px;
  color: #fff;
}

.plan_new_content p{
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
  opacity: 0.7;
}

.new_plan-section_two{
  padding: 100px 0px 60px 0px;
}

.plan_new_content_two h2{
  font-size: 30px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 60px;
}

.new_plan_card{
  background: #fff;
  border: 1px solid #c7c7c7;
  padding: 20px 30px 30px 30px;
  text-align: left;
  border-radius: 10px;
}

.new_plan_card img{
  width: 150px;
  margin-bottom: 15px;
}

.new_plan_card h3{
  font-size: 20px;
  font-weight: 800;
  text-transform: capitalize;
}

.new_plan_card p{
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  opacity: 0.8;
}

.new_plan-highlight{
  padding: 75px 0px;
}

.plan_new_highlight{
  background-color: #e2fff8;
  border-radius: 16px;
  box-shadow: rgba(17, 25, 46, 0.1) 0px 0px 0px 2px inset;
  padding: 20px;
}

.plan_new_highlight p{
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 28px;
  font-weight: 800;
  margin-bottom: 0px;
}

/*------------------Plan Section New CSS End--------------------*/

/*------------------Blog Page CSS Start--------------------*/

.blogPage_section {
  padding: 50px 0px 50px 0px;
}

.blog_content {
  text-align: left;
  border: 1px solid #c7c7c7;
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.blog_content .b-image {
  border-radius: 5px;
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.blog_content .b-date {
  display: block;
  font-size: 14px;
  margin: 15px 0px 7px 0px;
  font-weight: 500;
  color: #444;
}

.blog_content .b-date i{
  color: #ffa22b;
  margin-right: 8px;
}

.blog_content .b-title {
  font-size: 19px;
  font-weight: 800;
  margin-bottom: 5px;
}

.blog_content .b-title a {
  text-decoration: none;
  color: #212529;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 90%;
}


.blog_content .b-description {
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0px;
  text-overflow: ellipsis;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  color: #444;
}

.blog_content .b-btn {
  color: #ffffff;
  background: #ffa22b;
  text-decoration: none;
  padding: 5px 20px;
  border-radius: 100px;
  display: inline-block;
  margin: 10px 0px 10px 0px;
  font-size: 14px;
}

.blog_detail_content {
  padding: 50px 0px 50px 0px;
}

.blog_detail_main h1 {
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 8px;
  color: #212529;
  padding-right: 30px;
}

.blog_detail_main .blog_detail_date{
  margin-bottom: 12px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #444;
}

.blog_detail_main .blog_detail_date i {
  color: #ffa22b;
  margin-right: 8px;
}

.blog_detail_main img {
  margin-bottom: 20px;
  border-radius: 5px;
}

.blog_detail_main p {
  font-size: 16px;
}

.blog_detail_main .blog-content-area p {
  font-size: 17px;
  margin-bottom: 15px;
}

.blog_detail_main .blog-content-area ul,
.blog_detail_main .blog-content-area dl,
.blog_detail_main .blog-content-area ol {
  font-size: 17px;
  padding-left: 20px;
}

.blog_detail_main .blog-content-area ul li,
.blog_detail_main .blog-content-area ol li {
  line-height: 24px;
  padding: 3px 0px;
}

.blog_detail_main .blog-content-area ul li a,
.blog_detail_main .blog-content-area ol li a {
  text-decoration: none;
  color: #0da1a1;
  font-weight: 700;
}


.blog_detail_main .blog-content-area p a {
  text-decoration: none;
  font-weight: 700;
  color: #0da1a1;
}

.blog_detail_main .blog-content-area h2,
.blog_detail_main .blog-content-area h3,
.blog_detail_main .blog-content-area h4,
.blog_detail_main .blog-content-area h5,
.blog_detail_main .blog-content-area h6 {
  font-size: 20px;
  font-weight: 700;
  color: #111;
}

.blog_detail_sidebar h3 {
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 10px;
  border-left: 4px solid #ffa22b;
  padding-left: 15px;
  color: #222;
}

.blog_detail_sidebar {
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  padding: 25px 25px 10px 25px;
}

.blog_detail_sidebar ul {
  margin: 0px;
  padding: 0px;
}

.blog_detail_sidebar ul li p {
  margin-bottom: 0px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #444;
}

.blog_detail_sidebar ul li p i{
  color: #ffa22b;
  margin-right: 5px;
}

.blog_detail_sidebar ul li a {
  color: #111;
  font-weight: 700;
}

.blog_detail_sidebar ul li a:hover,
.blog_detail_sidebar ul li a:focus,
.blog_detail_sidebar ul li a:active {
  color: #0da1a1;
}

.blog_detail_sidebar ul li {
  line-height: 21px;
  font-size: 16px;
  border-bottom: 1px solid #cbcbcb;
  padding: 10px 0px;
}

.blog_detail_sidebar ul li:last-child {
  border-bottom: none;
}

.blog_form_widget{
  background: #e2fff8;
  margin-bottom: 35px;
  padding: 20px 30px 25px 30px;
  border-radius: 5px;
  border: 1px solid #b6d5cd;
}

.form_widget_title h3{
  font-weight: 800;
  margin-bottom: 2px;
  font-size: 27px;
}

.blog_detail_form .cont_form .form-control{
 height: 42px;
 border-radius: 5px;
}

.blog_detail_form .form-group{
  margin-bottom: 12px;
}


/*------------------Blog Page CSS End--------------------*/

/*------------------Listing Page CSS Start--------------------*/
.listing_search {
  background: #0da1a1;
  padding: 10px 0px;
  position: sticky;
  top: 75px;
  left: 0px;
  z-index: 99;
}

.internal_search-style .form-control {
  border-radius: 2px;
  border: none;
  height: 36px;
  font-weight: 600;
  background: #fff;
}

.internal_search-style .search_btn button {
  padding: 5px 0px;
  border-radius: 2px;
  font-size: 16px;
}

.internal_category_search .dropdown-menu {
  max-height: 180px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: auto;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0px 5px;
}

.internal_category_search .dropdown-menu .dropdown-item {
  padding: 4px 12px;
  font-size: 17px;
  font-weight: 300;
  color: #000;
}

.internal_location .dropdown-menu {
  max-height: 180px;
  overflow: hidden;
  overflow-y: scroll;
  min-height: auto;
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 0px 10px;
}

.internal_location .dropdown-menu .dropdown-item {
  padding: 4px 12px;
  font-size: 17px;
  font-weight: 300;
  color: #000;
}

.internal_category_search .dropdown-menu .dropdown-item:hover,
.internal_category_search .dropdown-menu .dropdown-item:focus,
.internal_category_search .dropdown-menu .dropdown-item:active,
.internal_category_search .dropdown-menu .dropdown-item:focus-visible {
  background-color: #e7e7e7;
  outline: none;
  color: #000;
}

.internal_location .dropdown-menu .dropdown-item:hover,
.internal_location .dropdown-menu .dropdown-item:focus,
.internal_location .dropdown-menu .dropdown-item:active,
.internal_location .dropdown-menu .dropdown-item:focus-visible {
  background-color: #e7e7e7;
  outline: none;
  color: #000;
}

.internal_location .input-group button {
  border-radius: 2px;
  background: #ffa22b;
  color: #ffffff;
  border: 1px solid #ffa22b;
  border-left: 0px;
  font-size: 14px;
}

.internal_search-style select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 98% !important;
  background-position-y: 7px !important;
}

.saleAd-left {
  position: sticky;
  top: 140px;
  left: 20px;
  z-index: 0;
  float: left;
  width: auto;
  margin-top: 20px;
}

.saleAd-left img {
  width: 150px;
  height: auto;
  border-radius: 5px;
}

.saleAd-right {
  position: sticky;
  top: 140px;
  right: 20px;
  z-index: 0;
  float: right;
  width: auto;
  margin-top: 20px;
}

.saleAd-right img {
  width: 150px;
  height: auto;
  border-radius: 5px;
}

.adv_found_err h5{
  font-size: 17px;
  background-color: #f8d7da;
  padding: 15px 10px;
  color: rgb(95, 33, 32);
  border-radius: 5px;
  border: 1px solid #f1aeb5;
  font-weight: 400;
}

.ad_listingView {
  padding: 0px 0px 70px 0px;
  min-height: 540px;
  background: #ebebeb;
}

.ad_listingView .form-check .form-check-input:checked {
  background-color: #0da1a1;
  border-color: #0da1a1;
  box-shadow: none !important;
}

.ad_listingView .form-check .form-check-label {
  font-weight: 600;
  font-size: 16px;
  padding: 5px 10px 5px 35px;
}

.global_search_group .btn{
  background: #d9d9d9;
  border-color: #cbcbcb;
  color: #4c4c4c;
  font-size: 13px;
}

.global_search{
  font-size: 15px !important;
  border-color: #cbcbcb !important;
  color: #000 !important;
}

.ad_listingView .form-check{
  margin-bottom: 0px;
}

.ad_listingView .search_result{
  padding: 7px 15px;
}

.ad_listingView .search_result h6{
  font-weight: 600;
  font-size: 16px;
}

.ad_listingView .form-check .form-check-input{
  margin-left: 8px;
  margin-top: 8px;
  border-color: #838383;
  font-size: 18px;
}

.planPrice-style h4 {
  font-size: 13px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 7px;
  letter-spacing: 0px;
}

.planPrice-style .ad_hours {
  font-weight: 700;
  font-size: 16px;
  color: #000;
  letter-spacing: -1px;
  border: 1px solid #000;
  border-radius: 100px;
  padding: 2px 0px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.planPrice-style .ad_hours .fa-clock {
  font-size: 15px;
  margin-right: 3px;
}

.internal_ads_row .planCard-style .planInfo-style {
  padding: 14px 15px;
}

.internal_ads_row .planPrice-style {
  padding: 7px 10px;
}

.internal_ads_row .planPrice-style .planBtn {
  padding: 2px 5px;
  font-size: 14px;
}

.internal_ads_row .planPrice-style .showNumber {
  padding: 0px 0px;
  font-size: 16px;
}

.internal_ads_row .planPrice-style .showNumber .fa {
  font-size: 12px;
}

.internal_ads_row .ad_worktype {
  padding: 2px 15px;
  margin-bottom: 5px;
  font-size: 15px;
}

.internal_ads_row .ad_services .ad_serviceList li {
  line-height: 19px;
}

.service_nearby {
  padding: 60px 0px 60px 0px;
  background: #fff;
}

.service_nearby .nearby_title {
  font-size: 27px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.roadside_nearby {
  padding: 60px 0px 70px 0px;
  background: #fff;
}

.roadside_nearby .roadside_title {
  font-size: 27px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 40px;
}

.service_nearby_list ul {
  list-style: none;
  columns: 4;
  text-align: left;
  margin: 0px;
  padding: 0px;
}

.service_nearby_list ul li a {
  font-size: 17px;
  display: block;
  line-height: 24px;
  padding-left: 30px;
  font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #000 !important;
  text-decoration: underline !important;
  text-transform: capitalize;
}

.service_nearby_list ul li a:hover,
.service_nearby_list ul li a:focus,
.service_nearby_list ul li a:active {
  color: #0da1a1 !important;
}

.service_nearby_list ul li {
  margin: 0;
  padding: 0;
  padding-left: 9px;
  padding-right: 10px;
  width: 100%;
  break-inside: avoid-column;
  list-style: none;
  position: relative;
}

.service_nearby_list ul li:last-child {
  border-right: none;
}

.roadside_nearby_list ul {
  list-style: none;
  columns: 4;
  text-align: left;
  margin: 0px;
  padding: 0px;
}

.roadside_nearby_list ul li a {
  font-size: 18px;
  display: block;
  line-height: 25px;
  padding-left: 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #000 !important;
  text-decoration: underline !important;
}

.roadside_nearby_list ul li a:hover,
.roadside_nearby_list ul li a:focus,
.roadside_nearby_list ul li a:active {
  color: #0da1a1 !important;
}

.roadside_nearby_list ul li {
  margin: 0;
  padding: 0;
  padding-left: 9px;
  padding-right: 10px;
  width: 100%;
  break-inside: avoid-column;
  list-style: none;
  position: relative;
}

.roadside_nearby_list ul li:last-child {
  border-right: none;
}

.arrow_link {
  width: 32px;
  height: auto;
  position: absolute;
  left: 0px;
  top: 5px;

}


/*------------------Listing Page CSS End--------------------*/

/*------------------Listing Detail Page CSS Start--------------------*/
.ad_detail_sec {
  padding: 50px 0px 50px 0px;
  background-image: url(assets/detail-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: left;
}

.ad_detail_sec2 {
  padding: 50px 0px 50px 0px;
}

.ad_detail_banner img {
  border-radius: 5px;
  height: 100%;
  border: 1px solid #fff;
}

.ad_detail_info .ad_company {
  font-weight: 800;
  margin-bottom: 15px;
  font-size: 24px;
  color: #fff;
}

.ad_detail_info .ad_address .fas {
  color: #fff;
  font-size: 12px;
  width: 25px;
  text-align: center;
  height: 25px;
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  left: 0px;
  top: 2px;
}

.ad_descrip h6 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.ad_descrip p {
  max-width: 100%;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 16px;
}

.ad_detail_info .ad_address {
  font-size: 16px;
  color: #fff;
  position: relative;
  padding-left: 35px;
  line-height: 24px;
}

.ad_services h5 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.detail_service {
  margin: 0px;
  padding: 0px;
  column-count: 2;
}

.detail_service li {
  list-style: square;
  margin-left: 20px;
  line-height: 24px;
  text-transform: capitalize;
}

.ad_contact_info .ad_hours {
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: -1px;
  border: 1px solid #ffffff;
  border-radius: 100px;
  padding: 5px 35px;
  text-transform: capitalize;
}

.ad_contact_info h6 {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: uppercase;
}

.ad_contact_info .ad_phone a {
  border-radius: 100px;
  width: 100%;
  margin-bottom: 8px;
  padding: 5px 20px;
  color: #000;
  font-size: 18px;
  background: #ffa22b;
  background: linear-gradient(360deg, rgb(255 162 43) 0%, rgb(255 212 155) 50%, rgb(255 162 43) 100%);
  text-decoration: none;
  font-weight: 800;
  display: block;
}

.ad_contact_info .ad_phone a .fa {
  font-size: 16px;
}

.ad_contact_info .ad_phone a:hover,
.ad_contact_info .ad_phone a:focus,
.ad_contact_info .ad_phone a:visited,
.ad_contact_info .ad_phone a:focus-visible {
  background: #f7a947 !important;
  box-shadow: none !important;
  color: #000 !important;
}

.ad_more_info {
  background: #0da1a1;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 30px;
  border: 1px solid #dbdbdb;
}

.ad_more_info p {
  margin-bottom: 0px;
  color: #fff;
}


.ad_more_info .ad_email a,
.ad_more_info .ad_website a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.detail_breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: "|" !important;
  font-weight: 100;
  font-family: inherit !important;
}

/*------------------Listing Detail Page CSS End--------------------*/

/*------------------Related List Page CSS Start--------------------*/
.related_ads {
  padding-top: 60px;
  background: #ebebeb;
}

.related_ads_list {
  padding: 40px 0px 60px 0px;
}

.related_title h4 {
  font-size: 27px;
  font-weight: 800;
  text-transform: capitalize;
}

/*------------------Related List Page CSS End--------------------*/

/*------------------Sticky Content CSS Start--------------------*/
.sticky_content_section {
  padding: 60px 0px 30px 0px;
  border-top: 1px solid #0000002d;
}

.sticky_content_text h4 {
  font-weight: 700;
  font-size: 21px;
  text-transform: capitalize;
}

.sticky_content_text p{
  font-size: 17px;
}

.sticky_content_text p a {
  text-decoration: none;
  color: #0da1a1;
}

/*------------------Sticky Content CSS End--------------------*/

/*------------------Related Blog CSS Start--------------------*/
.related_topic {
  background: #e2fff8 !important;
  border: none;
}

.related_blog_content {
  padding: 25px 25px 5px 25px;
  background: #fff;
  box-shadow: 0 5px 20px rgb(0 0 0 / 12%);
  border: none;
}

.related_blog_content .b-title a {
  width: 100%;
  white-space: normal;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.related_blog_content .b-title {
  line-height: 27px;
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 800;
}

.related_blog_content .b-date{
  margin: 10px 0px 7px 0px;
}

.related_blog_content .b-description {
  font-size: 17px;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  line-height: 24px;
}

.related_blog_content a{
  color: #212529;
}

/*------------------Related Blog CSS End--------------------*/



/*------------------Responsive CSS Start--------------------*/

@media screen and (max-width: 767px) {

  footer.pt-5 {
    padding-top: 15px !important;
  }

  .foot_text ul,
  .contact-list {
    display: block;
    text-align: left;
  }

  .foot_text ul li,
  .contact-list li {
    border-right: none;
    line-height: 24px;
    padding: 8px 0px;
    display: block;
    margin: 0px;
  }

  .foot_text a,
  .contact-list li a {
    padding: 0px 10px;
    font-size: 17px;
  }

  .navbar.sticky-top {
    padding: 5px 10px;
  }

  .logo {
    max-width: 140px;
  }

  .home_search h1 {
    font-size: 27px;
    text-align: center !important;
    margin-bottom: 5px;
  }

  .home_search h5 {
    font-size: 20px;
    margin-bottom: 15px !important;
    text-align: center !important;
  }

  .search_cont {
    display: none;
  }

  .home_search {
    padding: 25px 0px 30px 0px;
    background-image: none;
  }

  .searchIcon_section {
    display: none;
  }

  .about_section-1 {
    padding: 35px 0px;
  }

  .about_section-1 .section-title.mb-5 {
    margin-bottom: 20px !important;
  }

  .section-title h2 {
    font-size: 24px;
  }

  .section-title p {
    font-size: 17px;
    margin-bottom: 0px;
  }

  .ico-high {
    padding: 5px 10px;
    font-size: 24px;
    margin-bottom: 15px;
    width: 50px;
    height: auto;
  }

  .home_search .search_input {
    padding: 0px 15px;
  }

  .home_search .search_btn {
    margin-top: 10px;
  }

  .home_search .location_search {
    margin-top: 8px;
  }

  .search_input .location_search .input-group button {
    background: #0da1a1;
    border: 1px solid #0da1a1;
    color: #ffffff;
    padding: 0px 10px;
    font-size: 15px;
  }

  .search_input .category_search input,
  .search_input .location_search input {
    height: 45px;
    font-size: 16px;
    padding: 5px 15px;
  }

  .about-col-text h3 {
    font-size: 24px;
  }

  .about-col-text h4 {
    font-size: 20px;
    margin-top: 5px;
  }

  .about_section-2,
  .plan-section {
    padding: 30px 0px;
  }

  .plan-section .plan_title h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .plan-section .plan_title p {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .plan-section .plan_title p br {
    display: none;
  }

  .plan_tabs .nav-tabs li .nav-link {
    font-size: 15px;
    padding: 3px 12px;
  }

  .plan_tabs .nav-tabs li {
    margin: 0px 2px;
  }

  .plan_tabs .nav-tabs {
    margin-bottom: 30px !important;
  }

  .plan_tabs .planPrice-style {
    padding: 30px 15px 20px 15px;
    margin-top: 0px;
  }

  .plan_tabs .planPrice-style .showNumber,
  .plan_tabs .planPrice-style .planBtn {
    margin-bottom: 10px;
    padding: 5px 0px;
    font-weight: 700;
    font-size: 17px;
  }

  .plan_tabs .planPrice-style h3 {
    margin: 0px 0px 5px 0px;
    line-height: 30px;
    font-size: 36px;
  }

  .cardText p {
    line-height: 22px;
  }

  .ad_worktype {
    margin-bottom: 10px;
    margin-top: 0px;
    width: 100%;
  }

  .ad_services .ad_serviceList li {
    line-height: 20px;
  }

  .ad_description p {
    margin-top: 5px;
    margin-bottom: 0px !important;
  }

  .internal_ads_row .ad_description p{
    margin-bottom: 10px !important;
    margin-top: 3px;
  }

  .quote_modal .modal-dialog {
    padding: 0px 10px;
  }

  .form_btn .btn {
    font-size: 15px;
    padding: 6px 10px;
    width: 100% !important;
  }

  .quote_form .cont_form {
    margin-bottom: 10px;
  }

  .navbar .navbar-toggler {
    padding: 3px 5px !important;
  }

  .cust-nav a {
    text-align: left;
    padding: 5px 0px;
    font-size: 17px;
    font-weight: 700;
  }

  .cust-nav a:first-child {
    padding-top: 20px;
  }

  .cust-nav a:last-child {
    margin-bottom: 10px;
  }

  .cust-nav .app_btn {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    font-size: 16px;
    padding: 0.5rem 1rem !important;
  }

  .internal_ads_row .planPrice-style {
    padding: 15px 10px;
    border-radius: 0px;
  }

  .internal_ads_row .planPrice-style .showNumber {
    padding: 2px 0px;
    margin-bottom: 8px;
    font-size: 17px;
  }

  .internal_ads_row .planPrice-style .showNumber .fa {
    font-size: 14px;
  }

  .planPrice-style .ad_hours {
    padding: 5px 0px;
    margin-bottom: 8px;
  }

  .internal_ads_row .planPrice-style .planBtn {
    padding: 5px 5px;
    font-size: 15px;
  }

  .internal_ads_row .ad_worktype {
    margin-bottom: 10px;
    padding: 3px 15px;
    width: 100%;
    margin-top: 0px;
  }

  .internal_ads_row .ad_services .ad_serviceList li {
    line-height: 22px;
  }

  .internal_ads_row .ad_services .ad_serviceList {
    margin-bottom: 10px;
  }

  .listing_search {
    padding: 15px 0px;
    position: relative;
    top: 0px;
    left: 0px;
  }

  .ad-listing-header .internal_category_search,
  .ad-listing-header .internal_location,
  .ad-listing-header .internal_workType {
    padding: 0px 15px !important;
    margin-bottom: 7px;
  }

  .ad-listing-header .search_btn {
    margin-top: 2px;
    padding: 0px 15px;
  }

  .ad_detail_sec {
    padding: 30px 0px 15px 0px;
  }

  .related_title h4 {
    font-size: 22px;
  }

  .ad_banner img {
    border-radius: 0px;
  }

  .ad_more_info {
    margin-top: 10px;
    padding: 10px 15px 5px 15px;
  }

  .ad_more_info.d-flex {
    display: block !important;
  }

  .ad_more_info p {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .detail_service {
    column-count: 1;
  }

  .ad_detail_sec2 {
    padding: 20px 0px 30px 0px;
  }

  .ad_services h5,
  .ad_descrip h6 {
    font-size: 16px;
  }

  .ad_detail_info .ad_company {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .ad_detail_info .ad_address {
    line-height: 21px;
    font-size: 15px;
    padding-left: 30px;
  }

  .ad_detail_info .ad_address .fas {
    top: 2px;
    width: 20px;
    height: 20px;
    line-height: 8px;
  }

  .ad_detail_info.mb-4 {
    margin-bottom: 15px !important;
  }

  .ad_detail_sec .ad_contact_info.d-flex {
    display: block !important;
    text-align: center;
  }

  .related_ads {
    padding-top: 35px;
  }

  .related_ads_list {
    padding: 20px 0px 35px 0px;
  }

  .ad_contact_info .ad_phone a {
    padding: 3px 10px;
  }

  .ad_contact_info .ad_hours {
    padding: 2px 10px;
  }

  .breadcrumb-item.active {
    font-size: 15px;
  }

  .breadcrumb_section {
    padding: 5px 0px;
  }

  .detail_breadcrumb .breadcrumb {
    width: 100%;
  }

  .detail_breadcrumb .breadcrumb li:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    display: block;
    text-align: left;
  }

  .detail_breadcrumb .breadcrumb li:last-child {
    display: none;
  }

  .breadcrumb-item a {
    font-size: 15px;
  }

  .plan_tabs .planPrice-style h5 {
    margin-bottom: 10px;
  }

  .contactPage_section {
    padding: 30px 0px 0px 0px;
  }

  .contact_title h1 {
    font-size: 24px;
  }

  .contact_title h4 {
    font-size: 17px;
    padding: 0px 20px;
  }

  .contactPage_section .form_btn .w-25 {
    width: 100% !important;
  }

  .contactPage_section .form_btn {
    margin-top: 0px !important;
  }

  .contactInfo .icon_Info {
    width: 90px;
    padding: 10px;
    margin-top: 0px;
  }

  .contactInfo .icon_Info .fa {
    font-size: 18px;
  }

  .contactInfo .detail_Info h3 {
    margin-top: 20px;
  }

  .contactInfo .detail_Info h4 {
    margin-bottom: 25px;
  }

  .content_page {
    padding: 30px 0px;
  }

  .content_page_style h2 {
    font-size: 20px;
  }

  .content_page_style h3,
  .content_page_style h4,
  .content_page_style h5,
  .content_page_style h6 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .content_page_style p, .content_page_style ul {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .content_page_style ul li {
    line-height: 21px;
    padding: 3px 0px;
  }

  .content_page_style h2.mb-4 {
    margin-bottom: 10px !important;
  }

  .contactIcon {
    padding: 30px 0px 40px;
    border: none;
  }

  .contactIcon_outer {
    margin-top: 0px !important;
  }

  .d_app_section {
    padding: 30px 0px 50px 0px;
  }

  .app_content h3 {
    font-size: 21px;
  }

  .app_btn_img a img {
    width: 150px;
    margin-right: 0px;
  }

  .app_screenshot img {
    padding: 0px 0px 25px 0px;
  }

  .app_btn_img {
    justify-content: space-evenly;
  }

  .app_content h5 {
    text-align: center;
  }

  .ad_detail_banner {
    margin-bottom: 15px;
  }

  .ad_listingView {
    padding: 0px 0px 30px 0px;
    min-height: auto;
  }

  .plan_tabs .nav-tabs {
    padding-right: 0px;
  }

  .roadside_nearby .roadside_title,
  .service_nearby .nearby_title {
    font-size: 22px;
    padding: 0px 15px;
  }

  .service_nearby {
    padding: 35px 0px 35px 0px;
  }

  .roadside_nearby {
    padding: 35px 0px 35px 0px;
  }

  .service_nearby_list ul,
  .roadside_nearby_list ul {
    columns: 1;
  }

  .service_nearby_list ul li a,
  .roadside_nearby_list ul li a {
    padding-left: 25px;
    font-size: 17px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .roadside_nearby .roadside_title,
  .service_nearby .nearby_title {
    margin-bottom: 20px;
    text-decoration: none;
  }

  .arrow_link {
    width: 27px;
    left: 0px;
    top: 7px;
  }

  .category_search .dropdown-menu .dropdown-item,
  .location_search .dropdown-menu .dropdown-item {
    padding: 3px 12px;
    font-size: 17px;
  }

  .category_search .dropdown-menu,
  .location_search .dropdown-menu {
    max-height: 140px;
  }

  .internal_category_search .dropdown-menu,
  .internal_location .dropdown-menu {
    max-height: 140px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin: 0px 12px;
  }

  .scrolltop{
    right: 10px;
    bottom: 20px;
    font-size: 14px;
    padding: 3px;
    width: 45px;
    height: 45px;
  }

  .float_call{
    right: 10px;
    bottom: 80px;
    font-size: 20px;
    padding: 5px;
    width: 45px;
    height: 45px;
  }

  .blogPage_section {
    padding: 25px 0px 60px 0px;
  }

  .blog_content .b-title a {
    white-space: normal;
    line-height: 23px;
    width: 100%;
  }

  .blog_content .b-image{
    height: 170px;
  }

  .blog_detail_main h1 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
    padding-right: 0px;
  }

  .blog_detail_content {
    padding: 20px 0px 50px 0px;
  }

  .blog_detail_main .blog-content-area p {
    font-size: 17px;
  }

  .blog_detail_main .blog-content-area h2,
  .blog_detail_main .blog-content-area h3,
  .blog_detail_main .blog-content-area h4,
  .blog_detail_main .blog-content-area h5,
  .blog_detail_main .blog-content-area h6 {
    font-size: 18px;
    line-height: 24px;
  }

  .blog_detail_main .blog-content-area ul,
  .blog_detail_main .blog-content-area dl,
  .blog_detail_main .blog-content-area ol {
    font-size: 17px;
    padding-left: 15px;
  }

  .blog_detail_sidebar {
    margin-top: 25px;
    padding: 20px 15px 10px 15px;
  }

  .blog_detail_main img {
    margin-bottom: 10px;
  }

  .sticky_content_section {
    padding: 30px 0px 30px 0px;
  }

  .sticky_content_text h4 {
    line-height: 24px;
    font-size: 19px;
    margin-bottom: 5px;
  }

  .related_topic {
    padding: 35px 0px 35px 0px;
  }

  .related_blog_content {
    padding: 15px !important;
  }

  .related_blog_content.h-100 {
    height: auto !important;
  }

  .about-col-text {
    padding: 20px 20px 0px 20px !important;
    border-radius: 0px;
  }

  .about-col-text h6 {
    margin-bottom: 5px;
  }

  footer .social-icon ul li a {
    font-size: 18px;
  }

  footer .social-icon ul li h6 {
    font-size: 15px;
  }

  @keyframes shine {
    0% {
      left: -50px;
      opacity: 1;
      transform: translateX(0px) skewX(-15deg);
    }

    100% {
      left: 170px;
      opacity: 0.5;
      transform: translateX(5px) skewX(-15deg);
    }
  }

  .about_service {
    padding: 20px 15px;
  }

  .adv_found_err h5 {
    font-size: 16px;
    line-height: 21px;
    padding: 10px;
  }

  .shine::before{
    display: none;
  }

  .special_slim_ad {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .special_slim_ad img{
    height: 32px;
    object-fit: cover;
    border-radius: 3px;
  }

  .copy-right p{
  font-size: 15px;
  }

  .vendor_offer{
    margin-top: 100px;
    padding: 0px 0px 30px 0px;
  }

  .box_Img img{
    margin-bottom: 30px;
    padding: 0px 10px;
  }

  .plan-section{
    margin-bottom: 0px;
  }

  .ven_ofer_content h4{
    font-size: 24px;
    margin-bottom: 10px;
    padding: 0px;
  }

  .ven_ofer_content p{
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 22px;
    padding: 0px;
  }

  .vendor_register_btn .btn{
    padding: 3px 40px;
    font-size: 18px;
  }

  .vendor_offer .shape-img1{
    display: none;
  }

  .sale_animation{
    display: block;
    width: fit-content;
    margin-bottom: 7px !important;
    font-size: 12px;
    padding: 2px 5px;
  }

  .special_ad_shimmer{
    margin-top: 0px !important;
    padding: 10px 10px;
    margin-bottom: 5px;
  }

  .special_ad_shimmer h6{
    font-size: 17px;
  }

  .contactIcon {
    background: #ffffff;
  }

  #plan-tabs-tabpane-3 .planPrice-style{
    padding: 75px 10px 10px 10px;
  }

  .plan_ribbon{
    --t: 20px;
    padding: 2px 35px 13px calc(30px + var(--r));
  }

  #plan-tabs-tabpane-3 .planPrice-style h5{
    margin-bottom: 10px;
    font-size: 17px;
  }

  #plan-tabs-tabpane-3 .planPrice-style h3{
    margin-bottom: 5px;
    font-size: 32px;
  }

  #plan-tabs-tabpane-4 .planPrice-style h3{
    margin-bottom: 5px;
  }

  #plan-tabs-tabpane-4 .planPrice-style .showNumber{
    padding: 4px 0px;
  }

  #plan-tabs-tabpane-4 .planPrice-style .planBtn{
    padding: 5px 0px;
  }

  .internal_search-style .search_btn button{
    padding: 5px 0px;
  }

  .internal_search-style .form-control{
    padding: 0px 10px;
    height: 36px;
  }

  .internal_location .input-group button{
    padding: 0px 10px;
    line-height: 14px;
  }

  .internal_search-style select{
    background-position-y: 5px !important;
  }

  .search_btn button{
    padding: 7px 0px;
    font-size: 16px;
  }

  .search_input .category_search input, .search_input .location_search input{
    height: 42px;
    font-size: 17px;
    padding: 3px 12px;
  }

  .ad_listingView .search_result h6{
    font-size: 14px;
  }

  .ad_listingView .form-check .form-check-label{
    font-size: 14px;
    padding: 5px 10px 8px 22px;
  }

  .ad_listingView .form-check .form-check-input{
    font-size: 16px;
    margin-left: 0px;
    margin-top: 6px;
  }

  .ad_listingView .search_result {
    padding: 5px 0px 8px 0px;
  }

  .planCard-style .card-title{
    font-size: 18px;
  }

  .related_blog_content .b-title{
    font-size: 19px;
  }

  .related_blog_content .b-title a{
    line-clamp: none;
    -webkit-line-clamp: none;
  }

  .blog_content .b-title{
    font-size: 18px;
  }

  .blog_content{
    padding: 5px;
    margin-bottom: 15px;
  }

  .blog_content .b-btn{
    font-size: 14px;
  }

  .about_service h2{
    font-size: 20px;
  }

  .new_plan-section{
    padding: 35px 0px;
  }

  .plan_new_content h1{
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .plan_new_content p br{
    display: none;
  }

  .plan_new_content p{
    font-size: 17px;
    margin-bottom: 20px;
  }

  .plan_new_highlight p{
    font-size: 17px;
    line-height: 24px;
  }

  .plan_new_highlight{
    padding: 15px;
  }

  .new_plan-section_two {
    padding: 35px 0px 0px 0px;
  }

  .plan_new_content_two h2{
    font-size: 21px;
    margin-bottom: 30px;
  }

  .new_plan_card{
    padding: 10px 20px 25px 20px;
  }

  .new_plan_card img{
    width: 100px;
  }

  .new_plan_card h3{
    font-size: 18px;
  }

  .news_title h4{
    font-size: 24px;
  }

  .news_title {
    margin-bottom: 20px;
  }

  .news_title p{
    font-size: 16px;
    padding: 0px 15px;
  }

  .news_input .form-control{
    height: 38px;
    padding: 3px 15px;
    font-size: 16px;
    border-radius: 3px;
  }

  .news_input{
    margin-bottom: 10px;
  }

  .news_btn{
    font-size: 16px !important;
    height: 38px;
    padding: 3px 15px !important;
    border-radius: 3px !important;
  }

  .form_widget_title h3{
    font-size: 24px;
  }

  .blog_form_widget{
    padding: 20px 20px 20px 20px;
    margin-top: 20px;
  }

  .internal_location .dropdown-menu .dropdown-item, .internal_category_search .dropdown-menu .dropdown-item{
    font-size: 17px;
    padding: 3px 12px;
  }

  .cust-nav .highlight_call{
    border-left: 0px;
    text-align: center;
    margin-left: 5px;
    padding-left: 0px !important;
    line-height: 27px;
    margin-top: 10px;
  }

  .navbar .container {
    max-width: 100%;
  }


}

/*------------------Responsive CSS End--------------------*/